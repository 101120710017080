.motivo-h {
    padding: 10px;
    text-align: left;
}

.check-item {
    margin: 5px;
}

.my-modal {
    width: 90vw ;   /* Occupy the 90% of the screen width */
    max-width: 90vw;
}

.align-center {
    text-align: center;
    align-items: center;
}

.average-div {
    padding: 15px;
    margin-left: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.span-bold {
    font-weight: bold;
}

.span-bold-size {
    font-weight: bold;
    font-size: 35px;
}